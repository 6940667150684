import React from 'react';
import ArticlePage from 'components/pages/ArticlePage';
import { P, H1, H2, Link, Text } from 'components/ui';

const HowThisSiteWasMade = () => (
  <ArticlePage title="How this site was made" description="">
    <Text>
      <H1>How this site was made</H1>
      <P>
        When it came to building a website for myself, I’d already heard about{' '}
        <Link href="https://www.gatsbyjs.org/">Gatsby.js</Link> and how awesome
        it was for static sites. I couldn’t stand waiting to give it a try. We
        didn’t get along well though.
      </P>
      <P>
        In the beginning I needed something simple: to use Sass for styling and
        at the same time get my CSS bundles autoprefixed. Gatsby didn’t give me
        that out of the box.
      </P>
      <P>
        Sure, there were ways to{' '}
        <Link href="https://github.com/gatsbyjs/gatsby/issues/4535">
          work this around
        </Link>
        , but I’ve only just started. What if I wanted to ask more? Was there
        any point at all in using Gatsby or a similar static site generator?
      </P>
      <H2>Meet Gatsby</H2>
      <P>
        Let me tell you a bit more about Gatsby in case you’ve never met. It is
        not a library, but a framework. It won’t be a guest of your web app, but
        your app is going to rent a room in the Gatsby’s house.
      </P>
      <P>
        You’ll have to follow the rules. It is you who will have to move out the
        day you realize there’s no way together anymore. And probably you’re
        fine with that.
      </P>
      <P>
        By the way, there are the same issues with Next.js and react-static,
        though the latter{' '}
        <Link href="https://medium.com/@tannerlinsley/%EF%B8%8F-introducing-react-static-a-progressive-static-site-framework-for-react-3470d2a51ebc">
          looks promising.
        </Link>{' '}
        If you’re picking a React-based static site generator, I suggest you to
        go with it.
      </P>
      <H2>Not a hard thing to do</H2>
      <P>
        The limitations mentioned above are the reason why I gave up using any
        framework for building my website. I also wanted to get more server-side
        experience with React and Webpack.
      </P>
      <P>
        So I came up with a pure React app with manually implemented static
        content pre-rendering. It has all the features of React-based static
        site generators, but is easier to customize.
      </P>
      <P>
        I’m not going to dive into it’s technical details <em>right here</em>,
        since I believe the right place for that is GitHub. So feel free to
        clone or fork{' '}
        <Link href="https://github.com/sergeysolovev/site">my repo</Link>. There
        you will find a detailed README with info about supported features, how
        to get started and deploy the whole thing to Netlify.
      </P>
      <P>Please let me know if you have any questions.</P>
    </Text>
  </ArticlePage>
);

export default HowThisSiteWasMade;
